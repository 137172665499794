const themeColors = {
  aubergine: '#8e0045',
  black: '#000',
  blue: '#34495e',
  default: '#00a7e9',
  gold: '#ffcd00',
  gray: '#808287',
  green: '#197f79',
  hotpink: '#ff95bb',
  mint: '#33c289',
  orange: '#dd4210',
  salmon: '#e48f8f',
  'aubergine-dark': '#8e0045',
  'black-dark': '#000',
  'blue-dark': '#34495e',
  'default-dark': '#00a7e9',
  'gold-dark': '#ffcd00',
  'gray-dark': '#808287',
  'green-dark': '#197f79',
  'hotpink-dark': '#ff95bb',
  'mint-dark': '#33c289',
  'orange-dark': '#dd4210',
  'salmon-dark': '#e48f8f',
}

export function getThemeColor(agencySettings) {
  if (
    agencySettings.theme === 'custom' ||
    agencySettings.theme === 'custom-dark'
  ) {
    return agencySettings.theme_custom_color || '#000000'
  }
  return themeColors[agencySettings.theme]
}
