import { orderBy, first } from 'lodash'

export function useHeaderImage({
  property,
  type = 'agency-website-thumbnail',
}) {
  const headerImage = property.header_image
  if (headerImage) {
    const url = `${process.env.GATSBY_SWEEPBRIGHT_CDN_URL}/properties/presets/${type}/${headerImage.id}`
    return { url, ...headerImage }
  }
  return null
}

const Image = ({ property, type = 'agency-website-thumbnail', children }) => {
  const headerImage = useHeaderImage({ property, type })

  return children(headerImage)
}

export default Image
