import React from 'react'
import { useAgency } from '../../hooks/use-agency'
import { FormattedMessage } from 'react-intl'
import { getMinMax } from '../../utils/helpers'
import { formatMoneyAmount } from '../../utils/helpers'
import _ from 'lodash'

export function ProjectPrice({ project }) {
  const { negotiation } = project

  const [lowestPrice, highestPrice, lowestPricedUnit] = getMinMax(
    project.properties,
    'attributes.price.published_price.amount'
  )

  const currency = _.get(
    lowestPricedUnit,
    'attributes.price.published_price.currency'
  )

  const { settings } = useAgency()

  if (lowestPrice === 0 && highestPrice === 0) {
    return <FormattedMessage id="price-on-request" />
  }

  if (lowestPrice !== highestPrice) {
    return (
      <span className="propertyPrice">
        {formatMoneyAmount({ amount: lowestPrice, currency })}
        {' - '}
        {formatMoneyAmount({ amount: highestPrice, currency })}
        {negotiation === 'let' && ' / '}
        {negotiation === 'let' ? (
          <FormattedMessage id={settings.rent_period} />
        ) : null}
      </span>
    )
  }

  return (
    <span className="propertyPrice">
      {formatMoneyAmount({ amount: lowestPrice, currency })}
      {negotiation === 'let' && ' / '}
      {negotiation === 'let' ? (
        <FormattedMessage id={settings.rent_period} />
      ) : null}
    </span>
  )
}
