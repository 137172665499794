import _ from 'lodash'
import { config } from '../config'

export function getMinMax(items = [], path) {
  let min = Number.MAX_VALUE
  let max = 0
  let minItem = null
  let maxItem = null
  let noDataCount = 0
  for (let item of items) {
    const val = parseInt(_.get(item, path))
    if (!isNaN(val)) {
      if (val < min) {
        min = val
        minItem = item
      }
      if (val > max) {
        max = val
        maxItem = item
      }
    } else {
      noDataCount++
    }
  }
  // handle no data special case
  if (items.length === noDataCount) {
    return [0, 0]
  }

  return [min, max, minItem, maxItem]
}

export function isPropertyInRegionWithPEB(property) {
  const { country, postal_code } = property.attributes.location

  if (country !== 'BE') {
    return false
  }

  if (!postal_code) {
    return false
  }

  const numericPostalCode = parseInt(postal_code)

  if (
    (numericPostalCode >= 1300 && numericPostalCode <= 1499) ||
    (numericPostalCode >= 4000 && numericPostalCode <= 7999)
  ) {
    return true
  }

  return false
}

export function getPanoramaUrl(imageId) {
  return `https://${process.env.GATSBY_WEBSITE_API_URL}/agencies/${process.env.GATSBY_AGENCY_ID}/images/${imageId}/panorama`
}

export function formatMoneyAmount({ currency, amount }) {
  return new Intl.NumberFormat(
    config.numeral_system === 'decimal_comma' ? 'nl-be' : 'en-UK',
    {
      style: 'currency',
      currency: currency,
      useGrouping: true,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }
  ).format(amount)
}

export const replaceStatus = (status) => {
  if (status === 'agreement') {
    return 'sold'
  }

  return status
}
