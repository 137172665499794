import mapboxgl from 'mapbox-gl'

const MAPBOX_TOKEN =
  'pk.eyJ1Ijoic3dlZXBicmlnaHQiLCJhIjoiY2tiemR5cG55MDc3YjJ4cG1pZjh3N3hseiJ9.VfbsHxXvdjsInoaw9XX44g'

mapboxgl.accessToken = MAPBOX_TOKEN

function extractFeature(property) {
  if (
    property.attributes.location.geo?.longitude &&
    property.attributes.location.geo?.latitude
  ) {
    return {
      // feature for Mapbox DC
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          property.attributes.location.geo.longitude,
          property.attributes.location.geo.latitude,
        ],
      },
      properties: property,
    }
  }
}

export { mapboxgl, extractFeature }
